import {
  ContactShadows,

  Sky,
  Stats,
  PointerLockControls,
} from '@react-three/drei';
import { Suspense, useEffect, useState } from 'react';
import { CuboidCollider, Physics, RigidBody } from '@react-three/rapier';
import { ConvaiFPS } from './fps/convaiFPS';
import { Nikhil } from './models/Nikhil';
import { useThree } from '@react-three/fiber'

export const Experience = ({ client }) => {
  const [gravity, setGravity] = useState([0, 0, 0]);
  const { camera } = useThree()
  const [isUIActive, setIsUIActive] = useState(false);

  useEffect(() => {
    setGravity([0, -9.81, 0]);
    // Set camera position directly if needed
    camera.position.set(50, 50, 50); // or whatever position you want
    camera.rotation.set(0, 0, 0);

    // Add event listeners for UI interaction
    const handleUIInteraction = (e) => {
      if (e.target.closest('.info-wrapper') || e.target.closest('.container')) {
        setIsUIActive(true);
      } else {
        setIsUIActive(false);
      }
    };

    document.addEventListener('mousedown', handleUIInteraction);
    return () => document.removeEventListener('mousedown', handleUIInteraction);
  }, [camera]);

  return (
    <>
      <PointerLockControls 
        enabled={!isUIActive} // Disable controls when UI is active
      />
      {/* lights */}
      <ambientLight intensity={0.2} />
      <hemisphereLight
        skyColor={'#ffffff'}
        groundColor={'#ffffff'}
        intensity={1.2}
        castShadow
      />
      <directionalLight
        position={[500, 100, 500]}
        color={'#fcf9d9'}
        intensity={2}
        castShadow
      />

      {/* models */}
      {/* <Stats /> */} // FPS stats off
      <Suspense>
        <Physics gravity={gravity}>
          <ConvaiFPS />
        
          <Nikhil client={client} />
          <RigidBody type="fixed">
            <mesh rotation={[Math.PI / 2, 0, 0]} position={[0, -0.2, 0]}>
              <planeGeometry args={[1000, 1000]} />
              <meshStandardMaterial color="white" />
            </mesh>
            <CuboidCollider args={[5, 5, 0.1]} position={[0, 1.5, -3]} />
            <CuboidCollider
              args={[5, 5, 0.1]}
              position={[0, 1.5, 4]}
              rotation={[-Math.PI / 8, 0, 0]}
            />
            <CuboidCollider
              args={[5, 5, 0.1]}
              position={[0, -0.2, 0]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <CuboidCollider
              args={[5, 5, 0.1]}
              position={[3, 1.5, 0]}
              rotation={[0, Math.PI / 2, 0]}
            />
            <CuboidCollider
              args={[5, 5, 0.1]}
              position={[-3, 1.5, 0]}
              rotation={[0, Math.PI / 2, 0]}
            />
          </RigidBody>
        </Physics>
      </Suspense>
      <ContactShadows opacity={0.7} />
    </>
  );
};
