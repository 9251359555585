import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/logo.png';
import Thumbsdownoutline from '../../assets/Thumbsdownoutline.png';
import Thumbsdown_fill from '../../assets/Thumbsdown_fill.png';
import ThumbsUp_fill from '../../assets/Thumbsup_fill.png';
import Thumbsup_outline from '../../assets/Thumbsup_outline.png';
import '../../index.css';

const ChatBubblev2 = (props) => {
  var {
    userText,
    npcText,
    messages,
    userInput,
    chatHistory,
    keyPressed,
    setEnter,
    npcName,
    userName,
  } = props;
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const [feedbacks, setFeedbacks] = useState(Array(messages?.length).fill(0));

  //To keep the scroll bar fixed to the bottom
  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  });

  //Handles the input from the textBox
  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userInput(value);
    setEnter(1);
    setValue("");
  };

  const handleTalkButtonPress = () => {
    // Simulate the 'T' keydown event
    const keyEvent = new KeyboardEvent('keydown', {
      keyCode: 84,  // 'T' key
      bubbles: true,
      cancelable: true
    });
    window.dispatchEvent(keyEvent);
  };

  const handleTalkButtonRelease = () => {
    // Simulate the 'T' keyup event
    const keyEvent = new KeyboardEvent('keyup', {
      keyCode: 84,  // 'T' key
      bubbles: true,
      cancelable: true
    });
    window.dispatchEvent(keyEvent);
  };

  return (
    <>
      <section className="container">
        {chatHistory === "Show" ? (
          <div className="container-chat1" ref={containerRef}>
            {messages.map((message, idx) => {
              const isUserMessage = message.sender === "user";
              const nextMessage = messages[idx + 1];
              const isNextMessageUser = !nextMessage || nextMessage.sender === "user";
              const isNextMessageNpc = !nextMessage || nextMessage.sender === "npc";

              return (
                <section key={idx}>
                  {message.sender === "user" && isNextMessageNpc
                    ? message.content && (
                      <div className="user-message-display">
                        <p className="user-name-display">{userName}:</p>
                        <p className="message-content">{message.content}</p>
                      </div>
                    )
                    : message.sender === "npc" && isNextMessageUser
                      ? message.content && (
                        <div className="npc-message-container">
                          <div>
                            <span className="npc-name">{npcName}:</span>
                            <span className="message-content">{message.content}</span>
                          </div>
                          <div className="feedback-container">
                            <img
                              className="feedback-image"
                              src={feedbacks[idx] === 1 ? ThumbsUp_fill : Thumbsup_outline}
                              alt=""
                              onClick={() => {
                                const newFeedbacks = [...feedbacks];
                                newFeedbacks[idx] = feedbacks[idx] === 1 ? 0 : 1;
                                setFeedbacks(newFeedbacks);
                              }}
                            />
                            <img
                              className="feedback-image"
                              src={feedbacks[idx] === 2 ? Thumbsdown_fill : Thumbsdownoutline}
                              alt=""
                              onClick={() => {
                                const newFeedbacks = [...feedbacks];
                                newFeedbacks[idx] = feedbacks[idx] === 2 ? 0 : 2;
                                setFeedbacks(newFeedbacks);
                              }}
                            />
                          </div>
                        </div>
                      )
                      : ""}
                </section>
              );
            })}
          </div>
        ) : (
          <div className="container-chat1" ref={containerRef}>
            {userText && (
              <div>
                <div className="user-message-display">
                  <p className="user-name-display">{userName}:</p>
                  <p className="message-content">{userText}</p>
                </div>
                <div className="npc-message-container">
                  <div>
                    <span className="npc-name">{npcName}:</span>
                    <span className="message-content">{npcText}</span>
                  </div>
                  <div className="feedback-container">
                    {/* <img
                      className="feedback-image"
                      src={Thumbsup_outline}
                      alt=""
                    />
                    <img
                      className="feedback-image"
                      src={Thumbsdownoutline}
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="container-textBox">
          <div className="textBox">
            {keyPressed ? (
              <div className="icon voice-indicator">
                <span className="span-prop" />
                <span className="span-prop" />
                <span className="span-prop" />
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <input
                  className="placeholder1"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.01)",
                    borderWidth: "1px",
                    width: "100%",
                    color: "black",
                  }}
                  onChange={handleChange}
                  value={value}
                  placeholder="Naciśnij T by mówić, lub wpisz odpowiedź"
                  type="text"
                />
              </form>
            )}
          </div>
          <div className="logo">
            <img src={logo} height="25px" width="80px" alt=""></img>
          </div>
        </div>
      </section>

      <button 
        className="mobile-talk-button"
        onMouseDown={handleTalkButtonPress}
        onMouseUp={handleTalkButtonRelease}
        onTouchStart={handleTalkButtonPress}
        onTouchEnd={handleTalkButtonRelease}
      >
        {props.client?.keyPressed ? 'Puść, by zakończyć' : 'Naciśnij, by mówić'}
      </button>
    </>
  );
};

export default ChatBubblev2;
