import React, { useState, useCallback } from 'react';

const InfoBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleInfo = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (document.pointerLockElement) {
      document.exitPointerLock();
    }
    setIsOpen(prev => !prev);
  }, []);

  const handleOverlayClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
    // Re-enable pointer lock if needed
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.requestPointerLock();
    }
  }, []);

  const handleContentClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div className="info-wrapper" style={{ pointerEvents: 'auto' }}>
      <button 
        className="info-button"
        onClick={toggleInfo}
        aria-label="Information"
        style={{ pointerEvents: 'auto' }}
      >
        i
      </button>

      {isOpen && (
        <div 
          className="info-overlay" 
          onClick={handleOverlayClick}
          style={{ pointerEvents: 'auto' }}
        >
          <div 
            className="info-container"
            onClick={handleContentClick}
          >
            <button 
              className="info-close-button"
              onClick={toggleInfo}
              aria-label="Close"
            >
              ×
            </button>
            
            <div className="info-content">
              <h2>Wprowadzenie</h2>
              <p>Witaj w naszym interaktywnym środowisku czatu AI. Aplikacja umożliwia prowadzenie naturalnych rozmów z awatarem umieszczonym w przestrzeni 3D.</p>

              <h2>Swobodna rozmowa</h2>
              <p>Naciśnij i przytrzymaj klawisz 'T' na komputerze lub użyj przycisku "Naciśnij, by mówić" na urządzeniu mobilnym, aby rozmawiać z AI. Zwolnij przycisk, gdy skończysz mówić. AI odpowie naturalnie na Twoje polecenia głosowe.</p>

              <h2>Wprowadzanie tekstu</h2>
              <p>Możesz również wpisywać wiadomości w oknie czatu na dole ekranu. Naciśnij Enter, aby wysłać wiadomość.</p>

              <h2>Personalizacja awatara</h2>
              <p>Awatar znajduje się środku przestrzeni 3D. Można zmienić jego wygląd, głos, zachowanie i otoczenie.</p>

              <h2>Wersja mobilna</h2>
              <p>Na urządzeniach mobilnych użyj dedykowanego przycisku "Naciśnij, by mówić" na dole ekranu. Interfejs jest w pełni zoptymalizowany pod kątem obsługi dotykowej i różnych rozmiarów ekranu.</p>

              <h2>Informacja o prywatności</h2>
              <p>Wejście głosowe jest przetwarzane tylko wtedy, gdy aktywnie przytrzymujesz przycisk rozmowy lub klawisz 'T'. Twoje rozmowy są przetwarzane bezpiecznie i odpowiedzialnie.</p>

              <h2>Kontakt</h2>
              <p>Patryk Sadowski / patryk.sadowski@vivicam.tv / +48 530 530 663</p>
         
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoBox;
