import { Canvas } from '@react-three/fiber';
import { Experience } from './components/Experience';
import { KeyboardControls, Loader, OrbitControls } from '@react-three/drei';
import { useConvaiClient } from './hooks/useConvaiClient';
import ChatBubble from './components/chat/Chat';
import { useEffect, useState } from 'react';
import InfoBox from './components/InfoBox';

function App() {
  const { client } = useConvaiClient('d23f1516-a1bc-11ef-accc-42010a7be016', '409694715c7c35361f958b419ddd548b');

  return (
    <>
      <KeyboardControls
        map={[
          { name: 'forward', keys: [] },
          { name: 'backward', keys: [] },
          { name: 'up', keys: [] },
          { name: 'down', keys: [] },
          { name: 'left', keys: [] },
          { name: 'right', keys: [] },
          { name: 'sprint', keys: [] },
          { name: 'jump', keys: [] },  
        ]}
      >
        <Canvas
          style={{ touchAction: 'none' }}
          shadows
          camera={{
            fov: 35,
            near: 0.1,
            far: 1000
          }}
          onCreated={({ gl }) => {
            gl.domElement.requestPointerLock = undefined;
            gl.domElement.exitPointerLock = undefined;
            document.exitPointerLock = undefined;
          }}
        >
          <OrbitControls 
            enabled={false}
            enableZoom={false}
            enablePan={false}
            enableRotate={false}
          />
          <Experience client={client} />
        </Canvas>
      </KeyboardControls>
      <ChatBubble client={client} />
      <InfoBox />
    </>
  );
}

export default App;